.preloader {
  opacity: 0.99;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  right: 0;

  &.contrastDefault {
  }
  &.contrastWhite {
    .logo {
      background: white;
    }
  }
  &.contrastDark {
    .logo {
      background: black;
    }
  }
  &.contrastBlue {
    .logo {
      background: #9dd1ff;
    }
  }
  &.imageModeBW {
    .logo {
      svg {
        filter: grayscale(100%);
      }
    }
  }

  .logo {
    height: 100%;
    width: 100%;
    background: #174b77;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
      animation: smoke 1400ms ease-in-out forwards;
      animation-iteration-count: infinite;
      max-height: 100px;
      max-width: auto;
      @keyframes smoke {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.5);
        }
        100% {
          transform: scale(1);
        }
      }
    }
  }
}
