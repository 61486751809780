// .snowflakeContainer {
//   position: fixed; /* Make it cover the entire viewport */
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100vh;
//   pointer-events: none; /* Prevent interaction with other UI elements */
//   z-index: 9999; /* Ensure it overlays all content */
//   overflow: hidden;
// }

// .snowflake {
//   position: absolute;
//   top: -10px; /* Start above the viewport */
//   background-color: rgba(255, 255, 255, 1);
//   border-radius: 50%; /* Make it circular */
//   box-shadow: 0 0 4px rgba(255, 255, 255, 0.8); /* Subtle glow effect */
//   opacity: 0.6;
//   animation-name: fall;
//   animation-timing-function: linear;
//   animation-iteration-count: infinite;
// }

// @keyframes fall {
//   0% {
//     transform: translateY(0) rotate(0deg);
//   }
//   100% {
//     transform: translateY(100vh) rotate(360deg);
//   }
// }

.snowflakeContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  z-index: 9999;
  overflow: hidden;
}

.snowflake {
  position: absolute;
  top: -10px;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.9);
  animation-name: fall;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes fall {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}
