@import "../../responsiveMixins.scss";

.cookiesDisclaimerWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #da242b;
  font-family: "Exo 2", sans-serif;
  color: #fff;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100px);
  z-index: 999;
  opacity: 0.95;

  &.contrastDefault {
  }
  &.contrastWhite {
    background: white !important;
    color: black !important;
    a {
      color: black !important;
    }
    .accept {
      background: black !important;
      color: white !important;
    }
  }
  &.contrastDark {
    background: black !important;
    color: white !important;
    a {
      color: white !important;
    }
    .accept {
      background: white !important;
      color: black !important;
    }
  }
  &.contrastBlue {
    background: #9dd1ff !important;
    color: #174b77 !important;
    a {
      color: #174b77 !important;
    }
    .accept {
      background: #174b77 !important;
      color: #9dd1ff !important;
      border: transparent !important;
    }
  }

  &.visible {
    transform: translateY(0);
  }
  &:hover {
    cursor: default;
  }
  .cookiesDisclaimer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: calc(16px * var(--font-multiplier, 1));
    width: 100%;
    height: 100%;
    max-width: 1000px;
    margin: 0 auto;
    @include xs {
      max-width: 280px;
      font-size: calc(9px * var(--font-multiplier, 1));
    }
    @include sm {
      max-width: 350px;
      font-size: calc(9px * var(--font-multiplier, 1));
    }
    @include md {
      max-width: 600px;
      font-size: calc(11px * var(--font-multiplier, 1));
    }
    @include lg {
      max-width: 700px;
      font-size: calc(11px * var(--font-multiplier, 1));
    }
    @include xl {
      max-width: 1000px;
    }
    a {
      color: #fff;
    }
    .accept {
      // width: 50px;
      height: calc(25px * var(--font-multiplier, 1));
      border: solid 1px #fff;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      font-family: "Exo 2", sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      align-items: center;
      line-height: 1;
      color: #fff;
      background-color: #da242b;
      transition: all 0.2s ease-in-out;
      font-size: calc(12.5px * var(--font-multiplier, 1));
      &:hover {
        cursor: pointer;
        background-color: #fff;
        color: #da242b;
      }
    }
  }
}
