@import "../../responsiveMixins.scss";

.footerWrapper {
  width: 100%;
  height: 100%;
  // min-height: 30px;
  background-color: #174b77;
  font-family: "Exo 2", sans-serif;
  padding: 10px 0;
  cursor: default;
  &.contrastDefault {
  }
  &.contrastWhite {
    background: white;
    .privacy,
    p,
    .copyright,
    a {
      color: black !important;
    }
  }
  &.contrastDark {
    background: black;
    .privacy,
    p,
    .copyright,
    a {
      color: white !important;
    }
  }
  &.contrastBlue {
    background: #9dd1ff;
    .privacy,
    p,
    .copyright,
    a {
      color: #174b77 !important;
    }
  }
  .footer {
    width: 100%;
    max-width: 1000px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    // flex-direction: column;
    row-gap: 7px;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-weight: 300;
    font-size: calc(14px * var(--font-multiplier, 1));
    transition: all 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);

    @include xs {
      max-width: 280px;
      font-size: calc(9px * var(--font-multiplier, 1));
      flex-direction: column;
    }
    @include sm {
      max-width: 350px;
      font-size: calc(9px * var(--font-multiplier, 1));
      flex-direction: column;
    }
    @include md {
      max-width: 600px;
      font-size: calc(11px * var(--font-multiplier, 1));
      flex-direction: column;
    }
    @include lg {
      max-width: 700px;
      font-size: calc(11px * var(--font-multiplier, 1));
    }
    @include xl {
      max-width: 1000px;
    }
    p {
      text-align: center;
      // display: flex;
      // align-items: center;
      a {
        color: #fff;
      }
    }
    .copyright {
      a {
        font-weight: 700;
      }
    }
    .privacy {
      font-weight: 100;
      text-decoration: none !important;
    }
  }
}
