@import "../../responsiveMixins.scss";

.panelWrapper {
  position: relative;
  width: 100%;
  background: #174b77;
  z-index: 777;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.5s ease;

  &.visible {
    // position: static;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.5s ease;
  }

  &.hidden {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transform: translateY(-202px);
    transition: all 0.5s ease;

    @include xs {
      transform: translateY(-660px);
    }
    @include sm {
      transform: translateY(-660px);
    }
  }

  &.contrastDefault {
    .title {
      color: white !important;
    }
    .line {
      .control {
        button {
          &.active {
            //  border: 3px solid transparent; // Default active state border
          }

          &.active:focus-visible {
            outline: 2px solid black; // Ensure focus-visible remains visible
            outline-offset: 5px;
          }
        }
      }
    }
  }
  &.contrastWhite {
    background: white;
    .line {
      span {
        color: black !important;
      }
      .control {
        button {
          &.active {
            border: 3px solid black !important;
          }
        }
      }
      .fontMultiplier,
      .interval,
      .fontStyle,
      .images {
        .control {
          button {
            background: white !important;
            color: black !important;
            border: 1px solid black !important;
            &:hover {
              background: rgb(196, 196, 196) !important;
            }
          }
        }
      }
      .resetWrapper {
        .resetButton,
        .closeButton {
          background: black !important;
          color: white !important;
          outline: 1px solid black !important;
          &:hover {
            background: rgb(196, 196, 196) !important;
          }
        }
      }
    }
  }
  &.contrastDark {
    background: black;
    .line {
      span {
        color: white !important;
      }
      .control {
        button {
          border: none;
          &.active {
            border: 3px solid white !important;
          }
        }
      }
      .fontMultiplier,
      .interval,
      .fontStyle,
      .images {
        .control {
          button {
            background: black !important;
            color: white !important;
            border: 1px solid white !important;
            &:hover {
              background: #9f9f9f !important;
            }
          }
        }
      }
      .resetWrapper {
        .resetButton,
        .closeButton {
          background: white !important;
          color: black !important;
          outline: 1px solid white !important;
          &:hover {
            background: #9f9f9f !important;
          }
        }
      }
    }
  }
  &.contrastBlue {
    background: #9dd1ff;
    .line {
      span {
        color: #174b77 !important;
      }
      .control {
        button {
          border: none;
          &.active {
            border: 1px solid white !important;
            outline: 3px solid black !important;
          }
        }
      }
      .fontMultiplier,
      .interval,
      .fontStyle,
      .images {
        .control {
          button {
            background: #174b77 !important;
            color: #9dd1ff !important;
            outline: 1px solid #9dd1ff !important;
            &:hover {
              background: #9dd1ff !important;
              color: #174b77 !important;
              outline-color: #174b77 !important;
            }
          }
        }
      }
      .resetWrapper {
        .resetButton,
        .closeButton {
          background: #9dd1ff !important;
          color: #174b77 !important;
          outline: 1px solid #174b77 !important;
          &:hover {
            background: #174b77 !important;
            color: #9dd1ff !important;
          }
        }
      }
    }
  }

  .panel {
    width: 100%;
    max-width: 1000px;
    padding: 10px 0 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    transition: all 0.5s ease;
    z-index: 99;
    @include xs {
      max-width: 280px;
    }
    @include sm {
      max-width: 300px;
    }
    @include md {
      max-width: 600px;
    }
    @include lg {
      max-width: 700px;
    }
    @include xl {
      max-width: 1000px;
    }

    .line {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 15px;
      @include xs {
        flex-direction: column;
      }
      @include sm {
        flex-direction: column;
      }
    }
    .control {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 55px;
      gap: 15px;
      @include xs {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: unset;
      }
      @include sm {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: unset;
      }
      @include md {
        gap: 5px;
      }
      @include lg {
        gap: 5px;
      }
    }

    .fontMultiplier {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      width: 15%;
      gap: 5px;
      @include xs {
        width: 50%;
      }
      @include sm {
        width: 50%;
      }

      span {
        width: 100%;
        font-size: 18px;
        // font-size: calc(18px * var(--font-multiplier, 1));
        letter-spacing: normal;
        color: #fff;
        font-weight: 300;
        transition: all 0.5s ease;
        font-family: "Roboto Condensed", sans-serif;
      }
      .control {
        @include xs {
          gap: 8px;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
        @include sm {
          gap: 8px;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
      }
      button {
        background: #174b77;
        font-family: "Arial", sans-serif;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 3px;
        cursor: pointer;
        font-weight: bold;
        transition: all 0.3s ease;

        &:nth-child(1) {
          font-size: 14px;
          height: 40px;
          width: 40px;
          @include xs {
            height: 30px;
            width: 30px;
          }
          @include sm {
            height: 30px;
            width: 30px;
          }
        }
        &:nth-child(2) {
          font-size: 20px;
          height: 50px;
          width: 40px;
          @include xs {
            height: 40px;
            width: 35px;
          }
          @include sm {
            height: 40px;
            width: 35px;
          }
        }
        &:nth-child(3) {
          font-size: 28px;
          height: 55px;
          width: 40px;
          @include xs {
            height: 50px;
            width: 40px;
          }
          @include sm {
            height: 50px;
            width: 40px;
          }
        }

        &.active {
          border: 3px solid #fff;
        }

        &:hover {
          background: #2f76b5;
        }
      }
    }

    .contrast {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      width: 15%;
      gap: 5px;
      @include xs {
        width: 50%;
      }
      @include sm {
        width: 50%;
      }

      span {
        width: 100%;
        font-size: 18px;
        // font-size: calc(18px * var(--font-multiplier, 1));
        letter-spacing: normal;
        color: #fff;
        font-weight: 300;
        transition: all 0.5s ease;
        font-family: "Roboto Condensed", sans-serif;
      }

      .control {
        @include xs {
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          height: 50px;
        }
        @include sm {
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          height: 50px;
        }
      }
      button {
        border: 1px solid #fff;
        font-family: "Arial", sans-serif;
        border-radius: 3px;
        min-height: 40px;
        width: 40px;
        cursor: pointer;
        font-weight: bold;
        font-size: 20px;
        transition: all 0.3s ease;
        @include xs {
          min-height: 40px;
          width: 35px !important;
        }
        @include sm {
          min-height: 40px;
          width: 35px !important;
        }

        &:nth-child(1) {
          background: #fff;
          color: #000;
          &:hover {
            background: #b5b5b5;
          }
        }

        &:nth-child(2) {
          background: #000;
          color: #fff;
          &:hover {
            background: #2d2d2d;
          }
        }

        &:nth-child(3) {
          background: #0066cc;
          color: #fff;
          &:hover {
            background: #1c5791;
          }
        }

        &.active {
          border: 3px solid #fff;
        }
      }
    }

    .fontStyle {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      width: 28%;
      gap: 5px;

      @include xs {
        width: 100%;
      }
      @include sm {
        width: 100%;
      }

      span {
        width: 100%;
        font-size: 18px;
        // font-size: calc(18px * var(--font-multiplier, 1));
        letter-spacing: normal;
        color: #fff;
        font-weight: 300;
        transition: all 0.5s ease;
        font-family: "Roboto Condensed", sans-serif;
      }

      button {
        background: #174b77;
        font-family: "Arial", sans-serif;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 3px;
        min-height: 40px;
        width: 50%;
        cursor: pointer;
        font-size: 14px;
        // font-size: calc(14px * var(--font-multiplier, 1));
        letter-spacing: normal;
        font-weight: 700;
        transition: all 0.3s ease;

        @include xs {
          width: 100%;
        }
        @include sm {
          width: 100%;
        }

        &.active {
          border: 3px solid #fff;
        }
        &:hover {
          background: #2f76b5;
        }
      }
    }

    .images {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      width: 52%;
      gap: 5px;

      @include xs {
        width: 100%;
      }
      @include sm {
        width: 100%;
      }

      span {
        width: 100%;
        font-size: 18px;
        // font-size: calc(18px * var(--font-multiplier, 1));
        letter-spacing: normal;
        font-weight: 300;
        color: #fff;
        transition: all 0.5s ease;
        font-family: "Roboto Condensed", sans-serif;
      }

      button {
        background: #174b77;
        font-family: "Arial", sans-serif;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 3px;
        min-height: 40px;
        width: 33%;
        cursor: pointer;
        font-size: 14px;
        // font-size: calc(14px * var(--font-multiplier, 1));
        letter-spacing: normal;
        font-weight: 700;
        transition: all 0.3s ease;

        @include xs {
          width: 100%;
        }
        @include sm {
          width: 100%;
        }

        &.active {
          border: 3px solid #fff;
        }
        &:hover {
          background: #2f76b5;
        }
      }
    }

    .interval {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      width: 52%;
      gap: 5px;

      @include xs {
        width: 100%;
      }
      @include sm {
        width: 100%;
      }

      span {
        width: 100%;
        font-size: 18px;
        // font-size: calc(18px * var(--font-multiplier, 1));
        letter-spacing: normal;
        color: #fff;
        font-weight: 300;
        transition: all 0.5s ease;
        font-family: "Roboto Condensed", sans-serif;
      }

      button {
        background: #174b77;
        font-family: "Arial", sans-serif;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 3px;
        min-height: 40px;
        width: 33%;
        cursor: pointer;
        font-size: 14px;
        // font-size: calc(14px * var(--font-multiplier, 1));
        letter-spacing: normal;
        font-weight: 700;
        transition: all 0.3s ease;

        @include xs {
          width: 100%;
        }
        @include sm {
          width: 100%;
        }

        &:nth-child(1) {
          letter-spacing: normal !important;
        }

        &:nth-child(2) {
          letter-spacing: 0.15em !important;
        }

        &:nth-child(3) {
          letter-spacing: 0.25em !important;
        }

        &.active {
          border: 3px solid #fff;
        }
        &:hover {
          background: #2f76b5;
        }
      }
    }

    .resetWrapper {
      width: 28%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: flex-end;
      @include xs {
        width: 100%;
      }
      @include sm {
        width: 100%;
      }
      .resetButton {
        margin-bottom: 7px;
        background: #fff;
        color: #174b77;
        border: none;
        border-radius: 3px;
        padding: 8px 16px;
        min-height: 40px;
        width: 100%;
        cursor: pointer;
        font-size: 16px;
        // font-size: calc(16px * var(--font-multiplier, 1));
        letter-spacing: normal;
        transition: all 0.5s ease;
        font-weight: 700;
        text-transform: uppercase;
        &:hover {
          background: #d1d1d1;
        }
      }
      .closeButton {
        margin-bottom: 7px;
        background: #da242b;
        color: #fff;
        border: none;
        border-radius: 3px;
        padding: 8px 16px;
        min-height: 40px;
        width: 100%;
        cursor: pointer;
        font-size: 16px;
        // font-size: calc(16px * var(--font-multiplier, 1));
        letter-spacing: normal;
        transition: all 0.5s ease;
        font-weight: 700;
        text-transform: uppercase;
        &:hover {
          background: #b41318;
          color: #fff;
        }
      }
    }
  }
}
