@import "../../responsiveMixins.scss";

.snowToggle {
  position: relative;

  .wrapper {
    z-index: 10;
    position: fixed;
    bottom: 25px;
    left: 25px;

    //  border: 1px solid #174b77;
    border-radius: 25px;
    background-color: #ffffff45;
    padding: 7px;
    filter: drop-shadow(0px 0px 8px #ffffff90);

    transition: all 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);
    &:hover {
      background-color: #ffffff;
    }

    @include xs {
      width: 100px;
    }
    @include sm {
      width: 100px;
    }

    .checkbox_item {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      @include xs {
        flex-direction: column-reverse;
        gap: 5px;
      }
      @include sm {
        flex-direction: column-reverse;
        gap: 5px;
      }

      .title {
        font-size: 16px;
        color: #fff;
        color: #174b77;
        // text-shadow: 0px 0px 7px #fff;
        font-weight: 300;
        text-align: left;
        cursor: pointer;
        @include xs {
          font-size: 12px;
          text-align: center;
        }
        @include sm {
          font-size: 12px;
          text-align: center;
        }
      }
    }

    .checkbox_item label {
      @include xs {
        margin: 0 auto;
      }
      @include sm {
        margin: 0 auto;
      }
    }

    .checkbox_item .checkbox_wrap {
      position: relative;
      display: block;
      cursor: pointer;
      width: 50px;
      margin: 0 auto 50px;

      display: flex;
      align-items: center;
      justify-content: center;
      @include xs {
        margin: 0;
      }
      @include sm {
        margin: 0;
      }
    }

    .checkbox_item:last-child .checkbox_wrap {
      margin-bottom: 0;
    }

    .checkbox_item .checkbox_wrap .checkbox_inp {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
    }

    .checkbox_item .checkbox_wrap .checkbox_mark {
      display: inline-block;
      position: relative;
      border-radius: 25px;
    }

    .checkbox_item .checkbox_wrap .checkbox_mark:before,
    .checkbox_item .checkbox_wrap .checkbox_mark:after {
      content: "";
      position: absolute;
      transition: all 0.5s ease;
    }

    .checkbox_item .checkbox_wrap .checkbox_mark:before {
      content: "*";
      text-align: center;
      line-height: 24px;
      color: #fff;
    }
  }

  .checkbox_item .checkbox_wrap .checkbox_mark {
    border: 2px solid #174b7780;
    width: 50px;
    height: 25px;
    padding: 2px;
  }

  .checkbox_item .checkbox_wrap .checkbox_mark:before {
    top: 2px;
    left: 2px;
    width: 17px;
    height: 17px;
    background: #174b7780;
    border-radius: 25px;
  }

  .checkbox_item .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark {
    border-color: #174b77;
  }

  .checkbox_item .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark:before {
    left: 27px;
    background: #174b77;
  }
}
