@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap");

* {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: var(--typeface, "Roboto Condensed"), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
body::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #174b77;
}

.notification-container {
  padding: 10px 15px !important;
  font-family: var(--typeface, "Roboto Condensed"), sans-serif;
}

.disable-scroll {
  overflow: hidden;
  padding-right: 10px;
  background-color: #174b77;
}

/* DATEPICKER */

.react-datepicker__header {
  background-color: #f5f5f5 !important;
}

.react-datepicker__navigation-icon--previous::before {
  border-color: #174b77 !important;
  border-width: 3px 3px 0 0 !important;
  height: 9px !important;
  width: 9px !important;
  right: 0 !important;
}
.react-datepicker__navigation-icon--next::before {
  border-color: #174b77 !important;
  border-width: 3px 3px 0 0 !important;
  height: 9px !important;
  width: 9px !important;
  left: 0 !important;
}
.react-datepicker__navigation-icon:hover::before {
  border-color: #2474b9 !important;
}
.react-datepicker__current-month {
  color: #174b77 !important;
}

.react-datepicker__header--time {
  @media (min-width: 0px) and (max-width: 320px) {
    height: 53px !important;
  }
}
.react-datepicker__time-container,
.react-datepicker__time-box {
  @media (min-width: 321px) and (max-width: 480px) {
    width: 78px !important;
  }
}

.react-datepicker__day-name,
.react-datepicker__day {
  color: #174b77 !important;
}
.react-datepicker__day,
.react-datepicker__day-name {
  @media (min-width: 0px) and (max-width: 320px) {
    margin: 0 !important;
  }
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #174b77 !important;
  color: #f5f5f5 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #174b77 !important;
  color: #f5f5f5 !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #174b77 !important;
  color: #f5f5f5 !important;
}

.react-datepicker__navigation--previous {
  border-right-color: #174b77 !important;
}

.react-datepicker__navigation--next {
  border-left-color: #174b77 !important;
}

.react-datepicker__day--today {
  background-color: #f5f5f5 !important;
  color: #174b77 !important;
  text-decoration: underline !important;
}

.react-datepicker__day--today.react-datepicker__day--selected {
  color: #f5f5f5 !important;
}

.react-datepicker__header--time {
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

:root {
  --typeface: "Roboto Condensed";
  --font-size: 16px;
  --font-multiplier: 1;
  --letter-spacing: normal;
}

body {
  font-size: calc(var(--font-size) * var(--font-multiplier));
  letter-spacing: var(--letter-spacing, normal);
}

[data-theme="default"] {
  background-color: white;
  color: black;
}

[data-theme="light"] {
  background-color: white;
  color: black;
}

[data-theme="dark"] {
  background-color: black;
  color: white;
}

[data-theme="blue"] {
  background-color: #9dd1ff;
  color: white;
}

*:focus:not(:focus-visible) {
  outline: none;
}
*:focus-visible {
  outline: 2px solid #da242b !important;
  outline-offset: 5px !important;
  border-radius: 2px !important;
}
[data-theme="dark"] *:focus-visible {
  outline: 2px solid white !important;
}
[data-theme="light"] *:focus-visible {
  outline: 2px solid black !important;
}
