.toTop {
  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 9;
  &.contrastDefault {
  }
  &.contrastWhite {
    svg {
      path {
        fill: black !important;
      }
    }
  }
  &.contrastDark {
    svg {
      path {
        fill: white !important;
      }
    }
  }
  &.contrastBlue {
    svg {
      path {
        fill: #174b77 !important;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
  svg {
    max-width: 40px;
  }
}
