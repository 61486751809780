@import "./responsiveMixins.scss";

.appWrapper {
  width: 100%;
  height: 100%;
  background-color: #174b77;
  transition: all 0.5s ease;

  &.contrastDefault {
  }
  &.contrastWhite {
    .app,
    .header {
      background-color: white !important;
    }
  }
  &.contrastDark {
    .app,
    .header {
      background-color: black !important;
    }
  }
  &.contrastBlue {
    .app,
    .header {
      background-color: #9dd1ff !important;
    }
  }

  .app {
    min-height: 100vh;
    margin: 0;
    display: grid;
    grid-template-rows: auto 1fr auto;
    position: relative;
    background-color: #fff;
    transition: all 0.5s ease;
    .header {
      width: 100%;
      min-height: 50px;
      position: sticky;
      //    position: absolute;
      top: 0px;
      z-index: 890;
      background-color: #174b77;
      transition: all 0.5s ease;
      // @include xs {
      //   position: absolute;
      // }
      // @include sm {
      //   position: absolute;
      // }
    }

    .mainContent {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      margin: 0 auto;
    }

    .footer {
      min-height: 45px;
      width: 100%;
    }
  }
}

@keyframes deco-move {
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes deco-move {
  to {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
  }
}
