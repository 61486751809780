@import "../../responsiveMixins.scss";

.headerWrapper {
  width: 100%;
  min-height: 50px;
  height: 60px;
  background: #174b77;
  position: relative;
  display: flex;
  z-index: 888;
  transition: all 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);
  border-color: transparent;
  pointer-events: auto;

  &.imageModeDisabled {
    .title {
      display: block !important;
    }
  }

  &.contrastDefault {
    .title {
      color: white !important;
    }
    .hamburger {
      svg {
        path {
          fill: white !important;
        }
      }
    }
    .navigationMenu {
      ul {
        li {
          span {
            color: white;
          }
        }
      }
    }
  }
  &.contrastWhite {
    background-color: white;
    .title {
      color: black !important;
    }
    .hamburger {
      svg {
        path {
          fill: black !important;
        }
      }
    }
    .navigationMenu {
      ul {
        li {
          span {
            color: black !important;
          }
        }
        @include xs {
          background: white !important;
        }
        @include sm {
          background: white !important;
        }
      }
    }
    .backBtn {
      a {
        span {
          color: black !important;
        }
        svg {
          path {
            fill: black !important;
          }
        }
      }
    }
  }
  &.contrastDark {
    background: black;
    .title {
      color: white !important;
    }
    .hamburger {
      svg {
        path {
          fill: white !important;
        }
      }
    }
    .navigationMenu {
      ul {
        li {
          span {
            color: white !important;
          }
        }
        @include xs {
          background: black !important;
        }
        @include sm {
          background: black !important;
        }
      }
    }
    .backBtn {
      a {
        span {
          color: white !important;
        }
        svg {
          path {
            fill: white !important;
          }
        }
      }
    }
  }
  &.contrastBlue {
    background: #9dd1ff;
    .title {
      color: #174b77 !important;
    }
    .hamburger {
      svg {
        path {
          fill: #174b77 !important;
        }
      }
    }
    .navigationMenu {
      ul {
        li {
          span {
            color: #174b77 !important;
          }
        }
        @include xs {
          background: #9dd1ff !important;
        }
        @include sm {
          background: #9dd1ff !important;
        }
      }
    }
    .backBtn {
      a {
        span {
          color: #174b77 !important;
        }
        svg {
          path {
            fill: #174b77 !important;
          }
        }
      }
    }
  }
  .header {
    width: 100%;
    max-width: 1000px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    transition: all 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);
    @include xs {
      max-width: 280px;
    }
    @include sm {
      max-width: 300px;
    }
    @include md {
      max-width: 600px;
    }
    @include lg {
      max-width: 700px;
    }
    @include xl {
      max-width: 1000px;
    }

    .navigation {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 0rem;
      color: #fff;
      position: relative;
      .logo {
        text-decoration: none;
        display: flex;
        align-items: center;
        svg {
          margin-right: 1rem;
          &.blackWhite {
            filter: grayscale(100%);
          }
          @include md {
            height: 30px;
            width: auto;
          }
          @include lg {
            height: 35px;
            width: auto;
            margin-left: 0.5rem;
          }
        }
        .title {
          color: #fff;
          font-size: calc(17px * var(--font-multiplier, 1));
          line-height: calc(17px * var(--font-multiplier, 1));
          transition: all 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);
          @include xs {
            display: none;
          }
          @include sm {
            display: none;
          }
          @include md {
            font-size: calc(13px * var(--font-multiplier, 1));
            line-height: calc(13px * var(--font-multiplier, 1));
          }
          @include lg {
            font-size: calc(14px * var(--font-multiplier, 1));
            line-height: calc(14px * var(--font-multiplier, 1));
          }
        }
      }
      .hamburger {
        border: 0;
        height: 40px;
        width: 40px;
        padding: 0.5rem;
        // padding-right: 0;
        border-radius: 50%;
        background-color: transparent;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;
        position: absolute;
        top: 50%;
        right: 2.5px;
        transform: translateY(-50%) translateX(22%);
        display: none;
        &:hover {
          background-color: #1a5588;
        }
        @include xs {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          // margin-right: 45px;
        }
        @include sm {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          //  margin-right: 45px;
        }
        svg {
          path {
            fill: #fff;
            transition: all 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);
          }
        }
      }
      .navigationMenu {
        margin-left: auto;
        .accessibleModeToggle {
          background: transparent;
          outline: none;
          border: none;
        }
        &.expanded {
          ul {
            @include xs {
              display: block;
              width: 100%;
            }
            @include sm {
              display: block;
              width: 100%;
            }
          }
        }
        ul {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          column-gap: 1.7rem;
          padding: 0;
          @include xs {
            display: none;
            position: fixed;
            top: 60px;
            left: 0;
            flex-direction: column;
            width: 100vw;
            height: calc(100vh - 55px);
            background-color: #174b77;
          }
          @include sm {
            display: none;
            position: fixed;
            top: 55px;
            left: 0;
            flex-direction: column;
            width: 100vw;
            height: calc(100vh - 55px);
            background-color: #174b77;
          }
          @include md {
            column-gap: 0.7rem;
          }
          @include lg {
            column-gap: 1rem;
          }
          li {
            list-style-type: none;
            font-size: calc(17px * var(--font-multiplier, 1));
            //   line-height: calc(18px * var(--font-multiplier, 1));
            text-align: center;
            &:hover {
              span {
                color: #da242b;
              }
            }
            @include xs {
              text-align: center;
              margin: 0;
            }
            @include sm {
              text-align: center;
              margin: 0;
            }
            @include md {
              font-size: calc(12px * var(--font-multiplier, 1));
              //   line-height: calc(12px * var(--font-multiplier, 1));
            }
            @include lg {
              font-size: calc(14px * var(--font-multiplier, 1));
              //   line-height: calc(14px * var(--font-multiplier, 1));
            }
            @include xl {
              //     margin: 0 0 0 1.5rem;
            }
            @include xxl {
              //    margin: 0 0 0 2rem;
            }
            &:hover {
              @include xs {
                background-color: #eee;
              }
              @include sm {
                background-color: #eee;
              }
            }
            span {
              word-break: break-word;
              text-decoration: none;
              display: block;
              width: 100%;
              color: #fff;
              transition: all 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);
              &:hover {
                cursor: pointer;
              }
              @include xs {
                color: #fff;
                font-size: calc(17px * var(--font-multiplier, 1));
                //   line-height: calc(17px * var(--font-multiplier, 1));
                width: 100%;
                padding: 1rem 0;
              }
              @include sm {
                color: #fff;
                font-size: calc(24px * var(--font-multiplier, 1));
                //   line-height: calc(24px * var(--font-multiplier, 1));
                width: 100%;
                padding: 1rem 0;
              }
            }
          }
        }
        .adminButton {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            column-gap: 10px;
            @include xs {
              text-align: center;
              display: flex;
              align-items: center;
              column-gap: 10px;
              justify-content: center;
              margin: 0 auto;
              width: 80%;
            }
            @include sm {
              text-align: center;
              display: flex;
              align-items: center;
              column-gap: 10px;
              justify-content: center;
              margin: 0 auto;
              width: 80%;
            }
            span {
              color: #fff;
              font-weight: 700;
              font-size: calc(15px * var(--font-multiplier, 1));
              line-height: calc(16px * var(--font-multiplier, 1));
              transition: all 0.67s cubic-bezier(0.19, 1, 0.22, 1);
              @include xs {
                flex: 1;
              }
              @include sm {
                flex: 1;
              }
            }
            svg {
              height: 20px !important;
              width: 20px !important;
              transition: all 0.67s cubic-bezier(0.19, 1, 0.22, 1);
              path {
                fill: #fff;
              }
            }
            &:hover {
              cursor: pointer;
              span {
                color: #da242b;
              }
              svg {
                filter: none;
                path {
                  fill: #da242b !important;
                }
              }
            }
          }
          .logout {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            column-gap: 10px;
            @include xs {
              color: #fff;
              font-size: calc(17px * var(--font-multiplier, 1));
              //   line-height: calc(17px * var(--font-multiplier, 1));
              padding: 1rem 0;
              text-align: center;
              display: flex;
              align-items: center;
              column-gap: 10px;
              justify-content: center;
              margin: 0 auto;
              width: 80%;
            }
            @include sm {
              color: #fff;
              font-size: calc(24px * var(--font-multiplier, 1));
              //   line-height: calc(24px24px * var(--font-multiplier, 1));
              padding: 1rem 0;
              text-align: center;
              display: flex;
              align-items: center;
              column-gap: 10px;
              justify-content: center;
              margin: 0 auto;
              width: 80%;
            }
            span {
              color: #fff;
              font-weight: 700;
              font-size: calc(15px * var(--font-multiplier, 1));
              line-height: calc(16px * var(--font-multiplier, 1));
              transition: all 0.67s cubic-bezier(0.19, 1, 0.22, 1);
            }
            svg {
              height: 20px !important;
              width: 20px !important;
              transition: all 0.67s cubic-bezier(0.19, 1, 0.22, 1);
              path {
                fill: #fff;
              }
            }
            &:hover {
              cursor: pointer;
              span {
                color: #da242b;
              }
              svg {
                filter: none;
                path {
                  fill: #da242b !important;
                }
              }
            }
          }
          .adminTxt {
            @include md {
              display: none;
            }
            @include lg {
              display: none;
            }
            @include xl {
              display: none;
            }
            @include xxl {
              display: none;
            }
          }
        }
        .backBtn {
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            column-gap: 10px;
            span {
              color: #fff;
              font-weight: 700;
              font-size: calc(13px * var(--font-multiplier, 1));
              line-height: calc(13px * var(--font-multiplier, 1));
              transition: all 0.67s cubic-bezier(0.19, 1, 0.22, 1);
            }
            svg {
              // height: 22px !important;
              // width: 22px !important;
              height: calc(22px * var(--font-multiplier, 1));
              width: calc(22px * var(--font-multiplier, 1));

              color: #fff;
              transition: all 0.67s cubic-bezier(0.19, 1, 0.22, 1);
              path {
                fill: #fff;
              }
              @include xs {
                // height: 17px !important;
                // width: 17px !important;
                height: calc(17px * var(--font-multiplier, 1));
                width: calc(17px * var(--font-multiplier, 1));
              }
              @include sm {
                // height: 17px !important;
                // width: 17px !important;
                height: calc(17px * var(--font-multiplier, 1));
                width: calc(17px * var(--font-multiplier, 1));
              }
            }
            &:hover {
              cursor: pointer;
              span {
                color: #da242b;
              }
              svg {
                filter: none;
                path {
                  fill: #da242b !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
