@import "../../responsiveMixins.scss";

.button {
  cursor: pointer;
  padding: 10px;
  margin-right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: transparent;
  border: none;
  outline: none;
  &.onHome {
    @include xs {
      margin-right: 45px;
    }
    @include sm {
      margin-right: 45px;
    }
  }

  &.contrastDefault {
    svg {
      path {
        fill: white;
      }
    }
  }
  &.contrastWhite {
    svg {
      path {
        fill: black !important;
      }
    }
  }
  &.contrastDark {
    svg {
      path {
        fill: white !important;
      }
    }
  }
  &.contrastBlue {
    svg {
      path {
        fill: #174b77 !important;
      }
    }
  }

  &.modeActive {
    svg {
      path {
        fill: #da242b;
      }
    }
  }
  &:hover {
    svg {
      path {
        fill: #da242b;
      }
    }
  }
  svg {
    width: calc(24px * var(--font-multiplier, 1));
    height: auto;
    path {
      fill: #fff;
      transition: all 0.67s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
  background-color: transparent;
  border: none;
}
